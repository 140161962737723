/* global data */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { loadState } from 'helpers/localStorage';
import appReducer from 'reducers';

export const createBatonStore = () => {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const persistedState = loadState() || {};

  // See: https://www.pivotaltracker.com/story/show/167769152
  if (
    persistedState.state
    && persistedState.state.user
    && data.user
    && persistedState.state.user.id !== data.user.id
  ) {
    persistedState.state = {};
  }

  const { user } = data;
  const persistedStateAndUser = { ...persistedState.state, user };
  const store = createStore(
    appReducer(),
    persistedStateAndUser,
    composeEnhancer(
      applyMiddleware(
        thunk,
      ),
    ),
  );

  return store;
};

export const createEmptyStore = () => {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const state = {};

  const store = createStore(
    appReducer(),
    state,
    composeEnhancer(
      applyMiddleware(
        thunk,
      ),
    ),
  );

  return store;
};
